import moment from "moment";
import messageToUser from "../../helpers/messagesToUser.js"

export default {
    activeMessageCorrect: ({ commit, state }, mensaje) => {
        //mutation para cambiar el state y activar el mensaje "correcto"
        commit('SET_MESSAGE_CORRECT', {mensaje, bul:true});
        window.scroll(0,0)

        //desabilitamos ventana modal para mostrar interaz de mensajes
        commit('SET_BUL_MODAL', false);
    
        //volvemos todo a su estado inicial
        setTimeout(() => {
            commit('SET_MESSAGE_CORRECT', {mensaje: '', bul:false});
            commit('SET_BUL_MODAL', false);
        }, 3500);
    
    },
    activeMessageError: ({ commit, state }, mensaje) => {
        //declaracion de booleano para medir si estuvo activa la ventana modal
        let bulAction = false

        //mutation para cambiar el state y activar el mensaje "error"
        commit('SET_MESSAGE_ERROR', {mensaje, bul:true});
        window.scroll(0,0)

        //desabilitamos ventana modal para mostrar interaz de mensajes
        if(state.bulModal === true){
            bulAction = true
            commit('SET_BUL_MODAL', false);
        }
    
        //volvemos todo a su estado inicial
        setTimeout(() => {
            commit('SET_MESSAGE_ERROR', {mensaje: '', bul:false});
            if(bulAction === true){
                commit('SET_BUL_MODAL', true);
            }
        }, 3500);
    
    },
    tableSearchAction: ({ commit, state }, arrayState) => {
        const {value, data} = arrayState
        const newArrayFilter = state[data.data].filter((filterData) => {
            for (let clave in value[0]){
                if(value[0][clave][1] === "text"){
                    if(!value[0][clave][2]){
                        if(value[0][clave][0] !== ""){
                            if(value[0][clave][0].toLowerCase() !== String(filterData[clave]).substring(0,value[0][clave][0].length).toLowerCase()){
                                return
                            } 
                        } 
                    }else{
                        if(value[0][clave][0] !== ""){
                            if(value[0][clave][0].toLowerCase() !== String(value[0][clave][2](filterData[clave.substring(clave.length-1,0)])).substring(0,value[0][clave][0].length).toLowerCase()){
                                return
                            } 
                        } 
                    }
                } 
            }
            for (let clave in value[1]){
                if(value[1][clave][0] !== ""){ 
                    if(!value[1][clave][3]){
                        if(value[1][clave][2] === "desde"){  
                            if(Number(moment(value[1][clave][0],'YYYY-MM-DD hh:mm a').format('YYYYMMDDHHmm')) > Number(moment(filterData[clave.substring(clave.length-1,0)],'YYYY-MM-DD hh:mm a').format('YYYYMMDDHHmm'))){
                                return;
                            } 
                        }
                        if(value[1][clave][2] === "hasta"){
                            if(Number(moment(value[1][clave][0],'YYYY-MM-DD hh:mm a').format('YYYYMMDDHHmm')) < Number(moment(filterData[clave.substring(clave.length-1,0)],'YYYY-MM-DD hh:mm a').format('YYYYMMDDHHmm'))){
                                return;
                            }
                        }
                    }else{
                        if(value[1][clave][2] === "desde"){ 
                            if(Number(moment(value[1][clave][0],'YYYY-MM-DD hh:mm a').format('YYYYMMDDHHmm')) > Number(moment(value[1][clave][3](filterData[clave.substring(clave.length-2,0)],'YYYY-MM-DD hh:mm a').format('YYYYMMDDHHmm')))){
                                return;
                            } 
                        }
                        if(value[1][clave][2] === "hasta"){
                            if(Number(moment(value[1][clave][0],'YYYY-MM-DD hh:mm a').format('YYYYMMDDHHmm')) < Number(moment(value[1][clave][3](filterData[clave.substring(clave.length-2,0)],'YYYY-MM-DD hh:mm a').format('YYYYMMDDHHmm')))){
                                return;
                            }
                        }
                    }
                } 
            }
            for (let clave in value[2]){
                if(value[2][clave][0] !== ""){ 
                    if(!value[2][clave][3]){
                        if(value[2][clave][2] === "maximo"){
                            if(value[2][clave][0] < filterData[clave.substring(clave.length-1,0)]){
                                return;
                            } 
                        }
                        if(value[2][clave][2] === "minimo"){
                            if(value[2][clave][0] > filterData[clave.substring(clave.length-1,0)]){
                                return;
                            }
                        }
                    }else{
                        if(value[2][clave][2] === "maximo"){
                            if(value[2][clave][0] < value[2][clave][3](filterData[clave.substring(clave.length-2,0)])){
                                return;
                            } 
                        }
                        if(value[2][clave][2] === "minimo"){
                            if(value[2][clave][0] > value[2][clave][3](filterData[clave.substring(clave.length-2,0)])){
                                return;
                            }
                        }
                    }
                } 
            }
            return filterData
        })

        commit(data.dataFilterSet, newArrayFilter)
    },
    setStateManagmentData: ({commit, state}, booleanIsOpen) => {
        commit( 'SET_IS_OPEN_MANAGMENT_DATA' , booleanIsOpen)
    },
    async someFieldEmpty({dispatch}, {fields, cbError}){
        let arrayValuesFields = fields
        let isEmptySome = false

        if(typeof fields === "object" && !Array.isArray(fields)){
            arrayValuesFields = Object.values(fields)
        }        

        arrayValuesFields.forEach(field => {
            if(field === ""){
                isEmptySome = true
            }
        })

        if(isEmptySome){
            cbError ? cbError() : dispatch("activeMessageError", messageToUser.camposVacios)
        }

        return isEmptySome
    },
    async excessLimitCharacters({dispatch}, {fields, limit, cbError}){
        let arrayValuesFields = fields
        let isExcessSome = false

        if(typeof fields === "object" && !Array.isArray(fields)){
            arrayValuesFields = Object.values(fields)
        }
        
        arrayValuesFields.forEach(field => {
            if(typeof field === "string" && field.length > limit){
                isExcessSome = true
            }
        })

        if(isExcessSome){
            cbError ? cbError() : dispatch("activeMessageError", messageToUser.limiteCaracteresExcedido)
        }

        return isExcessSome
    },
    async setEventSubmit({commit}, value){
        const bul = value
        commit('SET_EVENT_SUBMIT', bul)
    },
    async changeErrorAnInputRequired({commit}, value){
        commit('SET_ERROR_INPUT_REQUIRED', value)
    }
}