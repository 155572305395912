export default {
    bulError: false,
    bulCorrecto: false,
    bulInfo: false,
    bulModal: true,
    language: 'esp',
    mensaje: "",
    isOpenManagmentData: false,
    errorAnInputRequired: false,
    eventSubmit: false,
}