import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'

const routes = [
    {
    path: '/',
    component: () => import('../pages/outApp/Template.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('../pages/outApp/Login.vue')
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('../pages/outApp/Register.vue')
      },
      {
        path: 'recover',
        name: 'Recover',
        component: () => import('../pages/outApp/Recover.vue')
      },
      {
        path: 'terms',
        name: 'Terms',
        component: () => import('../pages/outApp/Terms.vue')
      },
      {
        path: 'recoverypasswordurl/:id',
        name: 'RecoveryPasswordurl',
        component: () => import('../pages/outApp/Recoversucces.vue')
      },
      {
        path: 'Confirm',
        name: 'confirm',
        component: () => import('../pages/outApp/Confirm.vue')
      }
    ]
  },
  {
    path: '/app/',
    component: () => import('../pages/inApp/Template.vue'),
    children: [
      {
        path: 'profile',
        name: 'profile',
        component: () => import('../pages/inApp/Profile.vue')
      },
      {
        path: 'billetera',
        name: 'billetera',
        component: () => import('../pages/inApp/Billetera.vue')
      },
      {
        path: 'tickets',
        name: 'tickets',
        component: () => import('../pages/inApp/Tickets.vue')
      },
      {
        path: 'configuracion',
        name: 'configuracion',
        component: () => import('../pages/inApp/Configuracion.vue')
      },
    ]
  },
  {
    path: '/dashboard/',
    component: () => import('../pages/inProfile/Template.vue'),
    children: [
      {
        path: 'estadistica',
        name: 'estadistica',
        component: () => import('../pages/inProfile/DashboardGraficas.vue')
      },
      {
        path: 'cuenta',
        name: 'cuenta',
        component: () => import('../pages/inProfile/DashboardCochinito.vue'),
        children: [
          {
            path: 'inicio',
            name: 'inicio',
            component: () => import('../pages/inProfile/cuenta/Inicio.vue'),
          },
          {
            path: 'banco',
            name: 'banco',
            component: () => import('../pages/inProfile/cuenta/Banco.vue'),
          },
          {
            path: 'caja',
            name: 'caja',
            component: () => import('../pages/inProfile/cuenta/Caja.vue'),
          },
          {
            path: 'clientes',
            name: 'clientes',
            component: () => import('../pages/inProfile/cuenta/Clientes.vue'),
          },
          {
            path: 'inventario',
            name: 'inventario',
            component: () => import('../pages/inProfile/cuenta/Inventario.vue'),
          },
          {
            path: 'provedor',
            name: 'provedor',
            component: () => import('../pages/inProfile/cuenta/Provedor.vue'),
          },
        ]
      },
      {
        path: 'contabilidad',
        name: 'contabilidad',
        component: () => import('../pages/inProfile/DashboardContabilidad.vue')
      },
      {
        path: 'auditoria',
        name: 'auditoria',
        component: () => import('../pages/inProfile/DashboardAuditoria.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  /****** Declaracion de variables *******/ 
  let respuesta;
  let url;
  let url2;

  if(process.env.VUE_APP_TYPE === "Desarrollo"){
    url = process.env.VUE_APP_URL;
    url2 = "http://localhost:8081";
  }
  if(process.env.VUE_APP_TYPE === "Produccion"){
    url = process.env.VUE_APP_URL;
    url2 = "https://www.geslord.com/"
  }

  /****** Confirmacion de llave *******/ 

  async function actualizacionRespuesta() {
    if(localStorage.getItem('token') !== null){
      return respuesta = await axios.post(`${url}/login/ver`, {
        Authorization: localStorage.getItem('token') 
      })
    }else{
      return respuesta = await axios.post(`${url}/login/ver`, {
        Authorization: "nop" 
      })
    }
  }
  
  /****** Funcion de simplificacion de codigo (revisa si esta registrado, en cuyo caso lo manda a "/app/profile" y si no a "/login") *******/ 

  function checkRedirectData(respuesta, tipo){
    // tipo = 1 -----> ya esta en app/profile, no necesita redireccionamiento
    // tipo = 2 -----> esta en otra parte que no es /app/profile, y como esta logeado necesita redireccionamiento 
    if(respuesta !== undefined){
      if(respuesta.data.message === "no funciono, tiempo excedido"){
        return "/login"
      }
      if(respuesta.data.message === "no funciono"){
        return "/login"
      }
      if(respuesta.data.message === "no funciono, algo va mal"){
        return "/login"
      }
      if(respuesta.data.message === false){
        return "/login"
      }
      if(respuesta.data.message !== false && respuesta !== undefined){
        if(tipo === 1){
          return
        }
        if(tipo === 2){
          return "/app/profile"
        }
      }
      if(localStorage.getItem('token') === null){
        return "/login"
      }
    }else{
      return "/login"
    }
  }
  
  /****** CONDICIONALES *******/ 
  if(to.matched[0] === undefined){
    /****** si la url no se parece a ninguna de las presentes en la aplicacion *******/ 
    respuesta = await actualizacionRespuesta()
    next(checkRedirectData(respuesta,2))
  }else{
    if(to.matched[0].path === '/' && to.matched[1] === undefined){
      /****** caso especial, de cuando van a "/" solamente, esta pertene a las urls registrada, mas no se desea mostrar se *******/ 
      respuesta = await actualizacionRespuesta()
      next(checkRedirectData(respuesta,2))
    }
    if(to.matched[0].path === '/app/' && to.matched[1] === undefined){
      /****** caso especial, de cuando van a "/app/" solamente, esta pertenece en las urls registradas, mas no se desea mostrar se *******/ 
      respuesta = await actualizacionRespuesta()
      next(checkRedirectData(respuesta,2))
    }
    if(to.matched[0].path === '/dashboard/' && to.matched[1] === undefined){
      /****** caso especial, de cuando van a "/dashboard/" solamente, esta pertenece en las urls registradas, mas no se desea mostrar se *******/ 
      respuesta = await actualizacionRespuesta()
      next(checkRedirectData(respuesta,2))
    }
    if(to.matched[0].path === '/dashboard/' && to.matched[1].path === '/dashboard/cuenta' && to.matched[2] === undefined){
      /****** caso especial, de cuando van a "/dashboard/cuenta/" solamente, esta pertenece en las urls registradas, mas no se desea mostrar se *******/ 
      respuesta = await actualizacionRespuesta()
      next(checkRedirectData(respuesta,2))
    }
  }
  
  if(to.matched[1].path !== "/login" && to.matched[0].path !== "/login"){
    if(to.matched[0].path !== '/'){
      /****** determina en que url de la gestion se encuentra y lo identifica en el localstorage para mostrarlo en el header *******/ 
      if(to.matched[1].path === '/dashboard/estadistica'){
        localStorage.setItem('uri','1')
      }
      if(to.matched[1].path === '/dashboard/cuenta'){
        localStorage.setItem('uri','2')
        //para saber en que parte de cuenta nos encontramos
        if(to.matched[2].path === '/dashboard/cuenta/inicio'){
          localStorage.setItem('uri2','1')
        }
        if(to.matched[2].path === '/dashboard/cuenta/banco'){
          localStorage.setItem('uri2','2')
        }
        if(to.matched[2].path === '/dashboard/cuenta/inventario'){
          localStorage.setItem('uri2','3')
        }
        if(to.matched[2].path === '/dashboard/cuenta/clientes'){
          localStorage.setItem('uri2','4')
        }
        if(to.matched[2].path === '/dashboard/cuenta/provedor'){
          localStorage.setItem('uri2','5')
        }
        if(to.matched[2].path === '/dashboard/cuenta/caja'){
          localStorage.setItem('uri2','6')
        }
      }
      if(to.matched[1].path === '/dashboard/contabilidad'){
        localStorage.setItem('uri','3')
      }
      if(to.matched[1].path === '/dashboard/auditoria'){
        localStorage.setItem('uri','4')
      }
      /****** confirmacion de cuando esta en las url internas *******/ 
      respuesta = await actualizacionRespuesta()
      next(checkRedirectData(respuesta,1))
    }else{
      /****** permitir navegar entre las urls hijas de "/" *******/ 
      if(to.matched[0].path === '/' && to.matched[1].path === "/recoverypasswordurl/:id"){
        /****** recovery de password, verificacion de url nueva para cambiar password *******/ 
        let dato = await axios.post(`${url}/information/url`,{token: to.params.id})
        console.log(`${url2}${to.fullPath}`)
        if(dato.data.message === `${url2}${to.fullPath}`){
          /****** verficacion de token *******/
          let confirmacion = await axios.post(`${url}/login/ver`, {
            Authorization: to.params.id
          })
          next(checkRedirectData(confirmacion,1))
        }else{
          /****** ya se cambio la contrasena exitosamente, eliminacon de url *******/
          next("/login")
        }
      }else{
        /****** si no es recovery de contrasenia *******/ 
        next()
      }
    }
  }else{
    /****** para permitir /login *******/ 
    next()
  }
})

export default router
