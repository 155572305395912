//importaciones
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import GAuth from 'vue3-google-login'
import 'bootstrap'
import './styles/bootstrap.css'
import './styles/fonts.css'
import './styles/components.css'

//inicio de sesion con google
const gAuthOptions = { clientId: process.env.VUE_APP_CLIENT_ID || '1040753297974-bhhdfi4a65td4v406ramehrqdq6lbqsk.apps.googleusercontent.com'}

//inicializacion de la app
createApp(App).use(store).use(router).use(GAuth,gAuthOptions).mount('#app')