import axios from "axios"

export default {
    requestProvedores: async ({ commit, state }) => {
        const provedores = await axios.get(`${state.server}/information/consultaDatosProvedor`, {
            params: {
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            }}, 
            {headers: {'Content-Type': 'application/json'}
        });
        commit('SET_PROVEDORES', provedores.data.provedores);
        commit('SET_PROVEDORES_FILTROS', provedores.data.provedores);
    },
    agregarProvedorVuex: async ({commit, state, dispatch}, payload) => {
      try {
        const provedores = state.provedores
        const crearProvedorResponse = await axios.post(state.server +  "/information/crearProvedor", payload)
        if(crearProvedorResponse.status === 200){
          provedores.push(crearProvedorResponse.data.data)

          commit('SET_PROVEDORES', provedores);
          commit('SET_PROVEDORES_FILTROS', provedores);
  
          dispatch('activeMessageCorrect', crearProvedorResponse.data.message)
        }
      } catch (error) {
        if(error.response){
          console.log(error.response)
          dispatch('activeMessageError', (error.response !== undefined) 
              ? error.response.data.message 
              : 'ERROR'
          )
        }else{
          console.log(error)
        }        
      }
    },
    editarProvedorVuex: async({commit, state, dispatch}, payload) => {
      try {
        const provedores = state.provedores
        const editarProvedorResponse = await axios.post(state.server +  "/information/editarProvedor", payload)
        if(editarProvedorResponse.status === 200){
          provedores.forEach((data, index) => {
              if(data.Id_provedor === editarProvedorResponse.data.data[0].Id_provedor){
                  Object.assign(provedores[index], editarProvedorResponse.data.data[0])
              }
          })

          commit('SET_PROVEDORES', provedores);
          commit('SET_PROVEDORES_FILTROS', provedores);
  
          dispatch('activeMessageCorrect', editarProvedorResponse.data.message)
        }
      } catch (error) {
        if(error.response){
          console.log(error.response)
          dispatch('activeMessageError', (error.response !== undefined) 
              ? error.response.data.message 
              : 'ERROR'
          )
        }else{
          console.log(error)
        }        
      }
    },
    eliminarProvedorVuex: async ({commit, state, dispatch}, payload) => {
      try {
        const provedores = state.provedores
        const eliminarProvedorResponse = await axios.post(state.server + "/information/eliminarProvedor", payload)

        if(eliminarProvedorResponse.status === 200){
            const nuevosProvedores = provedores.filter((provedor) => provedor.Id_provedor !== payload.Id_provedor)

            commit('SET_PROVEDORES', nuevosProvedores);
            commit('SET_PROVEDORES_FILTROS', nuevosProvedores);
    
            dispatch('activeMessageCorrect', eliminarProvedorResponse.data.message)
        }

    } catch (error) {
        if(error.response){
            console.log(error.response)
            dispatch('activeMessageError', (error.response !== undefined) 
                ? error.response.data.message 
                : 'ERROR'
            )
        }else{
            console.log(error)
        }
    }      
  }
}