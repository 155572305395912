import bancoActions from "./bancoActions.js"
import cajaActions from "./cajaActions.js"
import clienteActions from "./clienteActions.js"
import codigoContableActions from "./codigoContableActions.js"
import componentsActions from "./componentsActions.js"
import sesionActions from "./sesionActions.js"
import filesActions from "./filesActions.js"
import provedorActions from "./provedorActions.js"

const actions = {
    ...bancoActions,
    ...cajaActions,
    ...clienteActions,
    ...codigoContableActions,
    ...componentsActions,
    ...filesActions,
    ...provedorActions,
    ...sesionActions,
}

export default actions

