export default {
    SET_BANCOS: (state, payload) => {
        state.bancos = payload
    },
    SET_BANCOS_FILTROS: (state, payload) => {
        state.bancosFiltros = payload
    },
    SET_MOVIMIENTOS_BANCOS: (state, payload) => {
        state.movimientosBancos = payload
    },
    SET_MOVIENTOS_BANCOS_FILTROS: (state, payload) => {
        state.movimientosBancosFiltros = payload
    },
    SET_STATUS_API_BANCO: (state, payload) => {
        state.statusApiBanco = payload
    },
}