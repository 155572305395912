export default {
    movimientosCajas: [],
    movimientosCajasFiltros: [],
    movimientosOtros: [],
    cajas: [],
    cajasFiltros: [],
    statusApiCodigoC: {
        message: "",
        success: ""
    },
    statusApiCaja: {
        message: "",
        success: ""
    }
}