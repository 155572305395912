import axios from "axios"

export default {
    //FUNCTION EXPORT DATA
    exportData: async ({ commit, dispatch, state }, data) => {

        try {

            const file = await axios({
                url: `${state.server}/files/exportarData`,
                method: 'GET',
                responseType: 'blob',
                headers: {'Content-Type': 'application/json'},
                params: {
                    ...data,
                    token: localStorage.getItem('token'),
                    tokenGestion: localStorage.getItem('token2')
                }, 
            });

            if(file.status === 200){
            
                let formato = (data.formato === 'XLSX (Excel)') 
                    ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
                    : 'application/pdf'

                var fileURL = window.URL.createObjectURL(new Blob([file.data], {type: formato}));
                var fileLink = document.createElement('a');
            
                fileLink.href = fileURL;
                fileLink.setAttribute('download', `${data.nombre}`);
                document.body.appendChild(fileLink);
            
                fileLink.click();
                document.body.removeChild (fileLink);
                window.URL.revokeObjectURL (fileURL);

                dispatch('activeMessageCorrect', 'Información descargada correctamente!!')
                return 'ok'

            }

        } catch (error) {
            if(error.response){
                console.log(error.response)
                dispatch('activeMessageError', (error.response !== undefined) 
                    ? error.response.data.message 
                    : 'ERROR'
                )
            }else{
                console.log(error)
            }           
        }
    },
}