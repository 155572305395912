import axios from 'axios'

export default {
    crearCodigoContable: async ({commit, dispatch, state}, codigo) => {
        const stateCc = state.codigosContables

        try {
            const codigoContable = await axios.post(`${state.server}/information/crearCodigoContable`, {
                ...codigo,
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            })


            if(codigoContable.status === 200){

                stateCc.push(codigoContable.data.data)

                const orderCc = stateCc.sort((a,b) => (a.Codigo_orden1 + a.Codigo_orden2) - (b.Codigo_orden1 + b.Codigo_orden2))

                commit('SET_CODIGOS_CONTABLES', orderCc);
                commit('SET_CODIGOS_CONTABLES_FILTROS', orderCc);

                dispatch('activeMessageCorrect', codigoContable.data.message)
                return 'ok'
            }

        }catch (error) {
            if(error){
                console.log(error.response)
                dispatch('activeMessageError', (error.response !== undefined) 
                    ? error.response.data.message 
                    : 'ERROR'
                )
            }
        }
    },
    editarCodigoContableVuex: async ({commit, dispatch, state}, codigo) => {
        const stateCc = state.codigosContables
        const stateData = state.cajas

        try {
            const codigoContable = await axios.post(`${state.server}/information/editarCodigoContable`, {
                ...codigo,
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            })


            if(codigoContable.status === 200){

                stateCc.forEach((data, index) => {
                    if(data.Id_cc === codigoContable.data.data[0].Id_cc){
                        Object.assign(stateCc[index], codigoContable.data.data[0])
                    }
                })

                stateData.forEach((data, index) => {
                    if(data.Cc_caja === codigoContable.data.data[0].Codigo){
                        stateData[index].Nombre_caja = codigo.nombre
                        stateData[index].Cc_caja = codigo.codigo
                    }
                })

                commit('SET_CODIGOS_CONTABLES', stateCc);
                commit('SET_CODIGOS_CONTABLES_FILTROS', stateCc);

                dispatch('requestMovimientosCajas')
                dispatch('activeMessageCorrect', codigoContable.data.message)
                return 'ok'
            }

        }catch (error) {
            if(error){
                console.log(error)
                dispatch('activeMessageError', (error.response !== undefined) 
                    ? error.response.data.message 
                    : 'ERROR'
                )
            }
        }
    },
    editarCodigoContableBancoVuex: async ({commit, dispatch, state}, codigo) => {
        const stateCc = state.codigosContables
        const stateData = state.bancos

        try {
            const codigoContable = await axios.post(`${state.server}/information/editarCodigoContable`, {
                ...codigo,
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            })


            if(codigoContable.status === 200){

                stateCc.forEach((data, index) => {
                    if(data.Id_cc === codigoContable.data.data[0].Id_cc){
                        Object.assign(stateCc[index], codigoContable.data.data[0])
                    }
                })

                stateData.forEach((data, index) => {
                    if(data.Cc_banco=== codigoContable.data.data[0].Codigo){
                        stateData[index].Agencia = codigo.nombre
                        stateData[index].Cc_banco = codigo.codigo
                    }
                })

                commit('SET_CODIGOS_CONTABLES', stateCc);
                commit('SET_CODIGOS_CONTABLES_FILTROS', stateCc);

                dispatch('requestMovimientosBancos')
                dispatch('activeMessageCorrect', codigoContable.data.message)
                return 'ok'
            }

        }catch (error) {
            if(error){
                console.log(error)
                dispatch('activeMessageError', (error.response !== undefined) 
                    ? error.response.data.message 
                    : 'ERROR'
                )
            }
        }
    },    
    eliminarCodigoContableVuex: async ({commit, dispatch, state}, codigo) => {
        const stateDataCaja = state.cajas
        const stateDataCc = state.codigosContables

        try {
            const cajaGestion = await axios.post(`${state.server}/information/eliminarCodigoContable`, {
                ...codigo,
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            })

            if(cajaGestion.status === 200){

                stateDataCaja.forEach((data, index) => {
                    if(data.Cc_caja === codigo.codigoContable){
                        stateDataCaja.splice(index,1)
                    }
                })

                stateDataCc.forEach((data, index) => {
                    if(data.Id_cc === codigo.idCodigo){
                        stateDataCc.splice(index,1)
                    }
                })

                commit('SET_CAJAS', stateDataCaja);
                commit('SET_CAJAS_FILTROS', stateDataCaja);
                commit('SET_CODIGOS_CONTABLES', stateDataCc);
                commit('SET_CODIGOS_CONTABLES_FILTROS', stateDataCc);

                dispatch('activeMessageCorrect', cajaGestion.data.message)
                return 'ok'
            } 

        }catch (error) {
            if(error.response){
                console.log(error.response)
                dispatch('activeMessageError', (error.response !== undefined) 
                    ? error.response.data.message 
                    : 'ERROR'
                )
            }else{
                console.log(error)
            }
        }
    },
    eliminarCodigoContableBancoVuex: async ({commit, dispatch, state}, codigo) => {
        const stateDataBanco = state.bancos
        const stateDataCc = state.codigosContables

        try {
            const eliminarCcRes = await axios.post(`${state.server}/information/eliminarCodigoContable`, {
                ...codigo,
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            })

            if(cajaGestion.status === 200){

                stateDataBanco.forEach((data, index) => {
                    if(data.Cc_banco === codigo.codigoContable){
                        stateDataBanco.splice(index,1)
                    }
                })

                stateDataCc.forEach((data, index) => {
                    if(data.Id_cc === codigo.idCodigo){
                        stateDataCc.splice(index,1)
                    }
                })

                commit('SET_BANCOS', stateDataBanco);
                commit('SSET_BANCOS_FILTROS', stateDataBanco);
                commit('SET_CODIGOS_CONTABLES', stateDataCc);
                commit('SET_CODIGOS_CONTABLES_FILTROS', stateDataCc);

                dispatch('activeMessageCorrect', eliminarCcRes.data.message)
                return 'ok'
            } 

        }catch (error) {
            if(error.response){
                console.log(error.response)
                dispatch('activeMessageError', (error.response !== undefined) 
                    ? error.response.data.message 
                    : 'ERROR'
                )
            }else{
                console.log(error)
            }
        }
    },
    requestCodigosContables: async ({ commit, state }) => {
        const codigosContables = await axios.get(`${state.server}/information/consultaCodigoContable`, {
            params: {
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            }}, 
            {headers: {'Content-Type': 'application/json'}
        });
        
        commit('SET_CODIGOS_CONTABLES', codigosContables.data.codigosContables)
        commit('SET_CODIGOS_CONTABLES_FILTROS', codigosContables.data.codigosContables)
    },
    setUltimoCodigoContableCaja: async({commit, state}) => {
        if(state.cajas.length !== 0){
            let referenceCode = state.cajas[state.cajas.length - 1].Cc_caja
            let lastNumCcCaja = Number(referenceCode.substring(9,referenceCode.length)) + 1
            let lastCcCaja = `1.1.1.01.${
                ((lastNumCcCaja) >= 10) 
                    ? lastNumCcCaja 
                    : `0${lastNumCcCaja}`
            }`

            commit('SET_ULTIMO_CODIGO_CONTABLE_CAJA', lastCcCaja)
            commit('SET_ULTIMO_CODIGO_CONTABLE_NUM_CAJA', lastNumCcCaja)
        }else{
            commit('SET_ULTIMO_CODIGO_CONTABLE_CAJA', '1.1.1.01.01')
            commit('SET_ULTIMO_CODIGO_CONTABLE_NUM_CAJA', 1)
        }
    },
    setUltimoCodigoContableBanco: async({commit, state}) => {
        if(state.bancos.length !== 0){
            let referenceCode = state.bancos[state.bancos.length - 1].Cc_banco
            let lastNumCcBanco = Number(referenceCode.substring(9,referenceCode.length)) + 1
            let lastCcBanco = `1.1.1.02.${
                ((lastNumCcBanco) >= 10) 
                    ? lastNumCcBanco 
                    : `0${lastNumCcBanco}`
            }`

            commit('SET_ULTIMO_CODIGO_CONTABLE_BANCO', lastCcBanco)
            commit('SET_ULTIMO_CODIGO_CONTABLE_NUM_BANCO', lastNumCcBanco)
        }else{
            commit('SET_ULTIMO_CODIGO_CONTABLE_BANCO', '1.1.1.02.01')
            commit('SET_ULTIMO_CODIGO_CONTABLE_NUM_BANCO', 1)
        }
    },
}
