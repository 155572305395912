import axios from "axios"

export default {
    //ACTION BANCO
    requestBancos: async ({ commit, state }) => {
        const bancos = await axios.get(`${state.server}/information/consultaDatosBanco`, {
            params: {
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            }}, 
            {headers: {'Content-Type': 'application/json'}
        });
        commit('SET_BANCOS', bancos.data.bancos);
        commit('SET_BANCOS_FILTROS', bancos.data.bancos);
    },
    crearBanco: async ({commit, dispatch, state}, bancoData) => {
        const stateBancos = state.bancos
        const stateNum = state.ultimoCodigoContableNumBanco

        try {
            const bancoResponse = await axios.post(`${state.server}/information/crearBanco`, {
                ...bancoData,
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            })
    
            if(bancoResponse.status === 200){
    
                stateBancos.push(bancoResponse.data.data)

                commit('SET_BANCOS', stateBancos);
                commit('SET_BANCOS_FILTROS', stateBancos);
                commit('SET_ULTIMO_CODIGO_CONTABLE_NUM_BANCO', Number(stateNum) + 1)
                commit(
                    'SET_ULTIMO_CODIGO_CONTABLE_BANCO', 
                    `1.1.1.02.${
                        ((Number(stateNum) + 1) >= 10) 
                            ? (Number(stateNum) + 1) 
                            : `0${(Number(stateNum) + 1)}`
                    }`
                )
                
                dispatch('activeMessageCorrect', bancoResponse.data.message)
                return 'ok'
                
            }            
        } catch (error) {
            if(error.response){
                console.log(error.response)
                dispatch('activeMessageError', (error.response !== undefined) 
                    ? error.response.data.message 
                    : 'ERROR'
                )
            }else{
                console.log(error)
            }           
        }
    },
    editarBancoVuex: async ({commit, dispatch, state}, codigo) => {
        const stateData = state.bancos
        const stateCc = state.codigosContables

        try {
            const banco = await axios.post(`${state.server}/information/editarBanco`, {
                ...codigo,
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            })

            if(banco.status === 200){

                stateData.forEach((data, index) => {
                    if(data.Id_banco === banco.data.data[0].Id_banco){
                        Object.assign(stateData[index], banco.data.data[0])
                    }
                })

                stateCc.forEach((data, index) => {
                    if(data.Codigo === banco.data.data[0].Cc_banco){
                        stateCc[index].Referencia = codigo.nombre
                    }
                })

                commit('SET_CODIGOS_CONTABLES', stateCc);
                commit('SET_CODIGOS_CONTABLES_FILTROS', stateCc);
                commit('SET_BANCOS', stateData);
                commit('SET_BANCOS_FILTROS', stateData);

                dispatch('requestMovimientosBancos')
                dispatch('activeMessageCorrect', banco.data.message)
                return 'ok'

            }

        }catch (error) {
            if(error.response){
                console.log(error.response)
                dispatch('activeMessageError', (error.response !== undefined) 
                    ? error.response.data.message 
                    : 'ERROR'
                )
            }else{
                console.log(error)
            }
        }
    },
    eliminarBancoVuex: async ({commit, dispatch, state}, bancoData) => {
        const bancos = state.bancos
        const codigosContables = state.codigosContables

        try {
        
            const eliminarBancoResponse = await axios.post(`${state.server}/information/eliminarBanco`, {
                ...bancoData,
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            })

            if(eliminarBancoResponse.status === 200){

                bancos.forEach((data, index) => {
                    if(data.Id_banco === bancoData.idCodigo){
                        bancos.splice(index, 1)
                    }
                })

                codigosContables.forEach((data, index) => {
                    if(data.Codigo === bancoData.codigo){
                        codigosContables.splice(index,1)
                    }
                })

                commit('SET_BANCOS', bancos);
                commit('SET_BANCOS_FILTROS', bancos);
                commit('SET_CODIGOS_CONTABLES', codigosContables);
                commit('SET_CODIGOS_CONTABLES_FILTROS', codigosContables);
                dispatch('activeMessageCorrect', eliminarBancoResponse.data.message)
                return 'ok'
            } 

        }catch (error) {
            
            if(error.response){
                console.log(error.response)
                dispatch('activeMessageError', (error.response !== undefined) 
                    ? error.response.data.message 
                    : 'ERROR'
                )
            }else{
                console.log(error)
            } 
        }
    },
    resetStatusApiBanco: async({commit}) => {
        commit('SET_STATUS_API_BANCO', {message: "", success:""})
    },
    //ACTION MOVIMIENTOS BANCOS
    requestMovimientosBancos: async ({ commit, state }) => {
        const bancos = await axios.get(`${state.server}/information/consultaDatosMovimientoBanco`, {
            params: {
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            }}, 
            {headers: {'Content-Type': 'application/json'}
        });

        commit('SET_MOVIMIENTOS_BANCOS', bancos.data.movimientoBanco);
        commit('SET_MOVIENTOS_BANCOS_FILTROS', bancos.data.movimientoBanco);        
    },
}