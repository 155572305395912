export default {
    SET_MOVIENTOS_CAJAS: (state, payload) => {
        state.movimientosCajas = payload;
    },
    SET_MOVIENTOS_CAJAS_FILTROS: (state, payload) => {
        state.movimientosCajasFiltros = payload;
    },
    SET_MOVIMIENTOS_OTROS: (state, payload) => {
        state.movimientosOtros = payload
    },
    SET_CAJAS: (state, payload) => {
        state.cajas = payload
    },
    SET_CAJAS_FILTROS: (state, payload) => {
        state.cajasFiltros = payload
    },
    SET_ALL_STATE_MOVIMIENTOS_BANCOS: (state, payload) => {
    
    },
    SET_ALL_STATE_MOVIMIENTOS_OTROS: (state, payload) => {
    
    },
    SET_STATUS_API_CODIGO_C: (state, payload) => {
        state.statusApiCodigoC = payload
    },
    SET_STATUS_API_CAJA: (state, payload) => {
        state.statusApiCaja = payload
    }
}