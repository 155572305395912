export default {
    'camposVacios': 'porfavor rellena los campos para crear una nueva caja',
    'limiteCaracteresExcedido': 'nombre caja no puede exceder los 30 caracteres',
    'codigoDesordenado': 'recuerda mantener el orden del codigo contable para caja "1.1.1.01."',
    'codigo creado': 'codigo creado',
    'codigoEditadoOk': 'Codigo editado con exito',
    'caja creada': 'caja creada con exito',
    'Error en validación': 'tiempo de sesion excedido',
    'Ya existe este codigo': 'Este codigo ya esta en uso',
    'Error movimientos existentes': 'No puedes eliminar una caja que tenga movimientos relacionados',
}