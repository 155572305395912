import bancoState from "./bancoState"
import cajaState from "./cajaState"
import clienteState from "./clienteState"
import codigoContableState from "./codigoContableState"
import componentsState from "./componentsState"
import provedorState from "./provedorState"
import sesionState from "./sesionState"


export default {
    server: process.env.VUE_APP_URL,
    ...bancoState,
    ...cajaState,
    ...clienteState,
    ...codigoContableState,
    ...componentsState,
    ...provedorState,
    ...sesionState
}