export default {
    SET_CODIGOS_CONTABLES: (state, payload) => {
        state.codigosContables = payload
    },
    SET_CODIGOS_CONTABLES_FILTROS: (state, payload) => {
        state.codigosContablesFiltros = payload
    },
    SET_ULTIMO_CODIGO_CONTABLE_CAJA: (state, payload) => {
        state.ultimoCodigoContableCaja = payload
    },
    SET_ULTIMO_CODIGO_CONTABLE_NUM_CAJA: (state, payload) => {
        state.ultimoCodigoContableNumCaja = payload
    },
    SET_ULTIMO_CODIGO_CONTABLE_BANCO: (state, payload) => {
        state.ultimoCodigoContableBanco = payload
    },
    SET_ULTIMO_CODIGO_CONTABLE_NUM_BANCO: (state, payload) => {
        state.ultimoCodigoContableNumBanco = payload
    },
}