import axios from "axios"

export default {
    dataForm: async ({commit, state}) => {

    },
    badRegisterFront: async ({commit, state}) =>{
        const success =  "error"
        const message = "error"

        commit('SET_STATE_LOGIN', { success: success, message: message})
    },
    register: async({commit, state}, data) => {
        let response = await axios.post(`${state.server}/information/create`, data)

        const success =  response.data.message === "Usuario creado" ? "ok" : "error"
        const message = response.data.message

        commit('SET_STATE_LOGIN', { success: success, message: message})
    },
    login: async ({commit, state}, user) => {
        const response = await axios.post(`${state.server}/login`, {
            email: user.email,
            password: user.password
        })
        
        //si hay token quiere decir que todo fue bien y se lo guarda en el message
        //sino se guarda el mensaje de error
        const success =  response.data.token ? "ok" : "error"
        const message = response.data.token ? response.data.token : response.data.message

        commit('SET_STATE_LOGIN', { success: success, message: message})
    },
    loginWithGoogle: async({commit, state}, func) => {
        //datos del inicio de sesion
        const googleUser = await func()

        let respuesta = await axios.post(`${state.server}/login/google`, {
            tokengoogle: googleUser.access_token
        })

        const success = respuesta.data.token !== undefined ? "ok" : "error"
        const message = respuesta.data.token

        commit('SET_STATE_LOGIN', { success: success, message: message})
    },
    recover: async ({commit, state},email) =>{
        let respuesta = await axios.post(`${state.server}/email/recuperar`, {
            email
        })

        const success = respuesta.data !== null ? "ok" : "error"
        const message = ""

        commit('SET_STATE_LOGIN', { success: success, message: message})
    },
    recoverSucces: async ({commit, state}) => {
        let mensaje = await axios.put(`${this.server}/information/updatepassword`, {
            contrasenia: this.password,
            token: this.$route.params.id
        })

        const success = mensaje.data.message === "hecho!" ? "ok" : "error"
        const message = mensaje.data.message

        commit('SET_STATE_LOGIN', { success: success, message: message})
    },
    confirmEmail: async ({commit, state}) => {
        let mensaje = await axios.post(`${state.server}/information/verificacionCodigoCorreo`, {
            token: localStorage.getItem('token'), 
            codigo: localStorage.getItem('codigo')
        });

        const success = mensaje.data.message === 'Usuario editado' ? "ok" : "error"
        const message = mensaje.data.message

        commit('SET_STATE_LOGIN', { success: success, message: message})        
    },
    clearStateLogin: async ({commit, state}) => {
        commit('SET_STATE_LOGIN', {success : "", message: ""})
    },
    sesionActive: async ({commit, state}) => {
        commit('SET_SESION_ACTIVE', true)
    },
    sesionInactive: async ({commit, state}) => {
        commit('SET_SESION_ACTIVE', false)
    }
}