import axios from "axios"

export default {
    //ACTION BANCO
    requestClientes: async ({ commit, state }) => {
        const clientesResponse = await axios.get(`${state.server}/information/consultaDatosCliente`, {
            params: {
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            }}, 
            {headers: {'Content-Type': 'application/json'}
        });
        commit('SET_CLIENTES', clientesResponse.data.clientes);
        commit('SET_CLIENTES_FILTROS', clientesResponse.data.clientes);
    },
    agregarClienteVuex: async ({commit, state, dispatch}, payload) => {
        try {
            const clientes = state.clientes
            const clientesFiltros = state.clientesFiltros
            const { Nombre_cliente, Activo_comercial, Rif, Dirrecion, Limite_de_credito, Trasporte, Contribuyente, Observaciones, Tipo_de_credito, Banco_domiciliario, Cuenta_domiciliaria, Contacto, Zona, Cobrador, Vendedor, Status, Factura_pendiente} = payload

            //ver que los campos no esten vacios
            const camposVacios = await dispatch('someFieldEmpty',{fields: payload})

            //campos con limites en caracteres
            const excedenLimite = await dispatch('excessLimitCharacters',{fields: { Nombre_cliente, Activo_comercial, Rif, Dirrecion, Limite_de_credito, Trasporte, Contribuyente, Observaciones, Tipo_de_credito, Banco_domiciliario, Cuenta_domiciliaria, Contacto, Zona, Cobrador, Vendedor, Status, Factura_pendiente}, limit: 30}) 

            if(camposVacios || excedenLimite){
                throw ({response: {data: {message: "Error en limite de caracteres o campos vaciós"}}})
            }
            
            const crearClienteResponse = await axios.post(state.server + "/information/crearCliente", 
            {...payload,
            Tipo_de_credito: "ninguno",  
            token: localStorage.getItem("token"), 
            tokenGestion: localStorage.getItem("token2")})

            if(crearClienteResponse.status === 200){
                clientes.push(crearClienteResponse.data.data)

                commit('SET_CLIENTES', clientes);
                commit('SET_CLIENTES_FILTROS', clientes);
        
                dispatch('activeMessageCorrect', crearClienteResponse.data.message)
            }

        } catch (error) {
            if(error.response){
                console.log(error.response)
                dispatch('activeMessageError', (error.response !== undefined) 
                    ? error.response.data.message 
                    : 'ERROR'
                )
            }else{
                console.log(error)
            }
        }
    },
    editarClienteVuex: async ({commit, state, dispatch}, payload) => {
        try {
            const clientes = state.clientes
            const clientesFiltros = state.clientesFiltros
            const { Nombre_cliente, Activo_comercial, Rif, Dirrecion, Limite_de_credito, Trasporte, Contribuyente, Observaciones, Tipo_de_credito, Banco_domiciliario, Cuenta_domiciliaria, Contacto, Zona, Cobrador, Vendedor, Status, Factura_pendiente} = payload

            //ver que los campos no esten vacios
            const camposVacios = await dispatch('someFieldEmpty',{fields: payload})

            //campos con limites en caracteres
            const excedenLimite = await dispatch('excessLimitCharacters',{fields: { Nombre_cliente, Activo_comercial, Rif, Dirrecion, Limite_de_credito, Trasporte, Contribuyente, Observaciones, Tipo_de_credito, Banco_domiciliario, Cuenta_domiciliaria, Contacto, Zona, Cobrador, Vendedor, Status, Factura_pendiente}, limit: 30}) 

            if(camposVacios || excedenLimite){
                throw ({response: {data: {message: "Error en limite de caracteres o campos vaciós"}}})
            }
                        
            const editarClienteResponse = await axios.post(state.server + "/information/editarCliente", {
                ...payload,
                Tipo_de_credito: "nada",  
                token: localStorage.getItem("token"), 
                tokenGestion: localStorage.getItem("token2")
            })

            if(editarClienteResponse.status === 200){
                clientes.forEach((data, index) => {
                    if(data.Id_caja === editarClienteResponse.data.data[0].Id_caja){
                        Object.assign(clientes[index], editarClienteResponse.data.data[0])
                    }
                })

                commit('SET_CLIENTES', clientes);
                commit('SET_CLIENTES_FILTROS', clientes);
        
                dispatch('activeMessageCorrect', editarClienteResponse.data.message)
            }

        } catch (error) {
            if(error.response){
                console.log(error.response)
                dispatch('activeMessageError', (error.response !== undefined) 
                    ? error.response.data.message 
                    : 'ERROR'
                )
            }else{
                console.log(error)
            }
        }
    },
    eliminarClienteVuex: async ({commit, state, dispatch}, payload) => {
        try {
            const clientes = state.clientes
            const clientesFiltros = state.clientesFiltros
            const eliminarClienteResponse = await axios.post(state.server + "/information/eliminarCliente", {Id_cliente: payload.Id_cliente, token: localStorage.getItem("token"), tokenGestion: localStorage.getItem("token2")})

            if(eliminarClienteResponse.status === 200){
                const nuevosClientes = clientes.filter((cliente) => cliente.Id_cliente !== payload.Id_cliente)

                commit('SET_CLIENTES', nuevosClientes);
                commit('SET_CLIENTES_FILTROS', nuevosClientes);
        
                dispatch('activeMessageCorrect', eliminarClienteResponse.data.message)
            }

        } catch (error) {
            if(error.response){
                console.log(error.response)
                dispatch('activeMessageError', (error.response !== undefined) 
                    ? error.response.data.message 
                    : 'ERROR'
                )
            }else{
                console.log(error)
            }
        }
    },
}