import bancoMutations from "./bancoMutations.js"
import cajaMutations from "./cajaMutations.js"
import clienteMutations from "./clienteMutations.js"
import codigoContableMutations from "./codigoContableMutations.js"
import componentsMutations from "./componentsMutations.js"
import provedorMutations from "./provedorMutations.js"
import sesionMutations from "./sesionMutations.js"

const mutations = {
    ...bancoMutations,
    ...cajaMutations,
    ...clienteMutations,
    ...codigoContableMutations,
    ...componentsMutations,
    ...provedorMutations,
    ...sesionMutations
}

export default mutations