export default {
    SET_MESSAGE_CORRECT: (state, {mensaje,bul}) => {
        state.bulCorrecto = bul
        state.mensaje = mensaje
    },
    SET_MESSAGE_ERROR: (state, {mensaje,bul}) => {
        state.bulError = bul
        state.mensaje = mensaje
    },
    SET_MESSAGE_INFO: (state, {mensaje,bul}) => {
        state.bulInfo = bul
        state.mensaje = mensaje
    },
    SET_BUL_MODAL: (state, bul) => {
        state.bulModal = bul
    },
    SET_IS_OPEN_MANAGMENT_DATA: (state, bul) => {
        state.isOpenManagmentData = bul
    },    
    SET_ERROR_INPUT_REQUIRED: (state, bul) => {
        state.errorAnInputRequired = bul
    },    
    SET_EVENT_SUBMIT: (state, bul) => {
        state.eventSubmit = bul
    },
    
}