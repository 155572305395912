import axios from 'axios'

export default {
    resetStatusApiCaja: async({commit}) => {
        commit('SET_STATUS_API_CAJA', {message: "", success:""})
    },
    resetStatusApiCodigoC: async({commit}) => {
        commit('SET_STATUS_API_CODIGO_C', {message: "", success:""})
    },
    //ACTION CAJA
    crearCajaGestion: async({commit, dispatch, state}, caja) => {
        const stateData = state.cajas
        const stateNum = state.ultimoCodigoContableNumCaja

        try {
            const cajaGestion = await axios.post(`${state.server}/information/crearCajaGestion`, caja)

            if(cajaGestion.status === 200){

                stateData.push(cajaGestion.data.data)

                commit('SET_CAJAS', stateData);
                commit('SET_CAJAS_FILTROS', stateData);
                commit('SET_ULTIMO_CODIGO_CONTABLE_NUM_CAJA', Number(stateNum) + 1)
                commit(
                    'SET_ULTIMO_CODIGO_CONTABLE_CAJA', 
                    `1.1.1.01.${
                        ((Number(stateNum) + 1) >= 10) 
                            ? (Number(stateNum) + 1) 
                            : `0${(Number(stateNum) + 1)}`
                    }`
                )

                dispatch('activeMessageCorrect', cajaGestion.data.message)
                return 'ok'
            }

        }catch (error) {
            if(error.response){
                console.log(error.response)
                dispatch('activeMessageError', (error.response !== undefined) 
                    ? error.response.data.message 
                    : 'ERROR'
                )
            }else{
                console.log(error)
            }
        }
    },
    editarCajaVuex: async ({commit, dispatch, state}, codigo) => {
        const stateData = state.cajas
        const stateCc = state.codigosContables

        try {
            const caja = await axios.post(`${state.server}/information/editarCaja`, {
                ...codigo,
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            })

            if(caja.status === 200){

                stateData.forEach((data, index) => {
                    if(data.Id_caja === caja.data.data[0].Id_caja){
                        Object.assign(stateData[index], caja.data.data[0])
                    }
                })

                stateCc.forEach((data, index) => {
                    if(data.Codigo === caja.data.data[0].Cc_caja){
                        stateCc[index].Referencia = codigo.nombre
                    }
                })

                commit('SET_CODIGOS_CONTABLES', stateCc);
                commit('SET_CODIGOS_CONTABLES_FILTROS', stateCc);
                commit('SET_CAJAS', stateData);
                commit('SET_CAJAS_FILTROS', stateData);

                dispatch('requestMovimientosCajas')
                dispatch('activeMessageCorrect', caja.data.message)
                return 'ok'

            }

        }catch (error) {
            if(error.response){
                console.log(error.response)
                dispatch('activeMessageError', (error.response !== undefined) 
                    ? error.response.data.message 
                    : 'ERROR'
                )
            }else{
                console.log(error)
            }
        }
    },
    eliminarCajaVuex: async ({commit, dispatch, state}, codigo) => {
        const stateDataCaja = state.cajas
        const stateDataCc = state.codigosContables

        try {
            const cajaGestion = await axios.post(`${state.server}/information/eliminarCaja`, {
                ...codigo,
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            })

            if(cajaGestion.status === 200){

                stateDataCaja.forEach((data, index) => {
                    if(data.Id_caja === codigo.idCodigo){
                        stateDataCaja.splice(index,1)
                    }
                })

                stateDataCc.forEach((data, index) => {
                    if(data.Codigo === codigo.codigo){
                        stateDataCc.splice(index,1)
                    }
                })

                commit('SET_CAJAS', stateDataCaja);
                commit('SET_CAJAS_FILTROS', stateDataCaja);
                commit('SET_CODIGOS_CONTABLES', stateDataCc);
                commit('SET_CODIGOS_CONTABLES_FILTROS', stateDataCc);

                dispatch('activeMessageCorrect', cajaGestion.data.message)
                return 'ok'
            } 

        }catch (error) {
            if(error.response){
                console.log(error.response)
                dispatch('activeMessageError', (error.response !== undefined) 
                    ? error.response.data.message 
                    : 'ERROR'
                )
            }else{
                console.log(error)
            }
        }
    },
    requestCajas: async ({ commit, state }) => {
        const cajas = await axios.get(`${state.server}/information/consultaDatosCaja`, {
            params: {
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            }}, 
            {headers: {'Content-Type': 'application/json'}
        });
    
        commit('SET_CAJAS', cajas.data.cajaGestion);
        commit('SET_CAJAS_FILTROS', cajas.data.cajaGestion);
    },
    //ACTION MOVIMIENTOS CAJAS
    requestMovimientosCajas: async ({ commit, state }) => {
        const movimientos = await axios.get(`${state.server}/information/consultaDatosMovimientoCaja`, {
            params: {
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            }}, 
            {headers: {'Content-Type': 'application/json'}
        })
            
        commit('SET_MOVIENTOS_CAJAS', movimientos.data.movimientoCaja);
        commit('SET_MOVIENTOS_CAJAS_FILTROS', movimientos.data.movimientoCaja);
    },
    addMovimientosCajas: async ({ commit, state}) => {
        const movimiento = await axios.post(`${this.server}/information/crearMovimientoCaja`, {
            arregloInformacion: this.arregloTodosLosMovimientos,
            token: localStorage.getItem('token'),
            tokenGestion: localStorage.getItem('token2')
        })
    },
    requestMovimientosOtros: async ({ commit, state }) => {
        const otros = await axios.get(`${state.server}/information/consultaDatosMovimientoOtros`, {
            params: {
                token: localStorage.getItem('token'),
                tokenGestion: localStorage.getItem('token2')
            }}, 
            {headers: {'Content-Type': 'application/json'}
        });

        commit('SET_MOVIMIENTOS_OTROS', otros.data.movimientoOtros);
        // commit('SET_MOVIENTOS_BANCOS_FILTROS', otros.data.movimientoOtros);
    },
}
