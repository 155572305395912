import cajaGetters from "./cajaGetters"
import componentsGetters from "./componentsGetters"
import configsGetters from "./configsGetters"
import sesionGetters from "./sesionGetters"

const actions = {
    ...cajaGetters,
    ...componentsGetters,
    ...configsGetters,
    ...sesionGetters
}

export default actions

